





























































import { computed, defineComponent, reactive, ref, set, toRefs } from '@vue/composition-api'
import { Select, Option, Tooltip, DatePicker, TimePicker, FormItem, Form } from 'element-ui'
import PreviewProject from '@/components/preview/PreviewProject.vue'
import { ProjectStatus, UpdateOrCreateProjectDto } from '@/api/project/model'
import { getProjectApi, updateOrCreateProjectApi, getTimezonesApi } from '@/api/project'
import { leaveConfirm, showError } from '@/utils/common'
import ProjectHeader from './components/ProjectHeader.vue'
import { localToISO, isoToLocal, isoToString, currentTimezone ,getTzText} from '@/utils/time-format'

export default defineComponent({
    components: {
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Tooltip.name]: Tooltip,
        [Select.name]: Select,
        [Option.name]: Option,
        [DatePicker.name]: DatePicker,
        [TimePicker.name]: TimePicker,
        PreviewProject,
        ProjectHeader
    },
    setup(props, { root }) {
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const isDisabledForm = ref(false)
        const formRef = ref()
        const formInfo: UpdateOrCreateProjectDto = reactive({
            project_name: '',
            title: '',
            cover: '',
            introduction: '',
            moderator_cover: '',
            moderator_name: '',
            limit_number: 50,
            number: 50,
            estimated_time: 60 * 15,
            expect_at: '',
            expect_date: '',
            expect_time: '',
            timezone: 'Asia/Shanghai',
            topic: '',
            industry: '',
            company: '',
            research_purpose: '',
            language: ''
        })
        const formRules = reactive({
            limit_number: [{ required: true, message: root.$i18n.t('projectTime.formRule1'), trigger: 'blur' }],
            estimated_time: [{ required: true, message: root.$i18n.t('projectTime.formRule2'), trigger: 'change' }],
            expect_date: [{ required: true, message: root.$i18n.t('projectTime.formRule3'), trigger: 'change' }],
            expect_time: [{ required: true, message: root.$i18n.t('projectTime.formRule4'), trigger: 'change' }],
            timezone: [{ required: true, message: root.$i18n.t('projectTime.formRule5'), trigger: 'change' }]
        })
        const formInfoCopy = {}
        const detailObj: any = reactive({})
        const getProject = async () => {
            try {
                const { details, status } = await getProjectApi({
                    uuid: projectUuId.value
                })
                isDisabledForm.value = [ProjectStatus.Published, ProjectStatus.Start, ProjectStatus.End].includes(status)
                Object.keys(details).forEach((key) => {
                    set(detailObj, key, details[key])
                })
                Object.keys(formInfo).forEach((key) => {
                    formInfo[key] = details[key]
                })
                if(formInfo.expect_at) {
                    const expect_at = isoToLocal(formInfo.expect_at || '', formInfo.timezone)
                    formInfo.expect_time = formInfo.expect_date = expect_at
                }
                formInfo.estimated_time = formInfo.estimated_time || 60 * 15
                Object.assign(formInfoCopy, formInfo)
                console.log(formInfo)
            } catch (error) {
                showError(error)
            }
        }
        getProject()

        const timeOptions = ref([
            {
                value: 60 * 15,
                label: root.$t('projectTime.timeItems.900')
            },
            {
                value: 60 * 30,
                label: root.$t('projectTime.timeItems.1800')
            },
            {
                value: 60 * 45,
                label: root.$t('projectTime.timeItems.2700')
            },
            {
                value: 60 * 60,
                label: root.$t('projectTime.timeItems.3600')
            },
            {
                value: 60 * (60 + 15),
                label: root.$t('projectTime.timeItems.4500')
            },
            {
                value: 60 * (60 + 30),
                label: root.$t('projectTime.timeItems.5400')
            },
            {
                value: 60 * (60 + 45),
                label: root.$t('projectTime.timeItems.6300')
            },
            {
                value: 60 * (60 + 60),
                label: root.$t('projectTime.timeItems.7200')
            },
            {
                value: 60 * (60 + 60 + 15),
                label: root.$t('projectTime.timeItems.8100')
            }
        ])
        interface Timezone {
            utc: string;
            id: string;
        }

        const getTimezoneText = (utc: string, id: string) => `(${utc}) ` + root.$t(`timezone.${id}`)

        const timezones = ref<Timezone[]>([]);
        const timezoneMap = new Map<string, string>();
        const defaultTimezone = 'Asia/Shanghai'
        timezoneMap.set(defaultTimezone, getTimezoneText('UTC+08:00', defaultTimezone));

        const getTimezones = async () => {
            try {
                const tz = await getTimezonesApi()
                const formattedTimezones = tz.map((item: Timezone) => ({
                    utc: item.utc,
                    id: item.id,
                    label: getTimezoneText(item.utc, item.id)
                }));
                formattedTimezones.forEach((item: Timezone) => {
                    timezoneMap.set(item.id, getTimezoneText(item.utc, item.id));
                });
                timezones.value = formattedTimezones;
            } catch (error) {
                showError(error)
            }
        }

        getTimezones()

        const systemTimeText = computed(() => {
            const tz = currentTimezone()
            const tzText = timezoneMap.get(tz)
            if (expectAtTime.value === '') return root.$t('projectIntroduce.unStartTime')
            return isoToString(expectAtTime.value, tz, 'YYYY-MM-DD HH:mm') + ' ' + getTzText(tz)
        });

        const pickerOptions = {
            disabledDate(v: any) {
                return v.getTime() < new Date().getTime() - 86400000
            }
        }

        const expectAtTime = computed(() => {
            const tz = currentTimezone()
            const d = new Date(formInfo.expect_date)
            const t = new Date(formInfo.expect_time)
            d.setHours(t.getHours())
            d.setMinutes(t.getMinutes())
            try {
                const time = isoToLocal(d.toISOString(), tz)
                return localToISO(time, formInfo.timezone || 'Asia/Shanghai')
            } catch (error) {
                // console.log("expectAtTime:", error)
                return ''
            }
        })

        const submitInfo = reactive({
            isSubmitLoad: false,
            isDisabled: computed(() => {
                return !Object.keys(formRules).every((key) => formInfo[key]) || isDisabledForm.value
            }),
            handleForm: () => {
                formInfo.expect_at = expectAtTime.value
                formInfo.number = formInfo.limit_number
            },
            onSubmitFrom: (isNext = false) => {
                formRef.value.validate().then(async () => {
                    submitInfo.handleForm()
                    submitInfo.isSubmitLoad = true
                    try {
                        const option = { ...formInfo }
                        if (projectUuId.value) {
                            option.project_uuid = projectUuId.value
                        }
                        await updateOrCreateProjectApi(option)
                        root.$message.success(root.$t('saveSuccess').toString())
                        if (isNext) {
                            root.$router.push({
                                name: 'ProjectWordCloud'
                            })
                        } else {
                            getProject()
                        }
                    } catch (error) {
                        showError(error)
                    }
                    submitInfo.isSubmitLoad = false
                })
            }
        })

        const goNext = async () => {
            try {
                await leaveConfirm(formInfo, formInfoCopy)
                root.$router.push({
                    name: 'ProjectInvite'
                })
            } catch (error) {
                console.log(error)
            }
        }

        return {
            isDisabledForm,
            projectUuId,
            formInfo,
            formRules,
            formRef,
            detailObj,
            timeOptions,
            timezones,
            goNext,
            submitInfo,
            pickerOptions,
            systemTimeText,
        }
    }
})
