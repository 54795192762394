



































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        info: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    setup() {}
})
